
/* Styles for desktop users */
@media only screen and (min-width: 769px) {

    #hero {
        text-align: center;
        overflow: hidden;
    }

    #hero h1
    {
        font-family: 'Anton', sans-serif;
        font-size: 6em;
        text-shadow: 1px 3px 8px rgba(16, 255, 255, 1.0);

        text-align: center;
    }

    #hero h2
    {
        font-family: 'Russo One', sans-serif;
        font-size: 2em;

        text-align: right;
    }

    #hero ul {
        list-style: none;
    }

    #hero #titleText {
        margin-top: 4em;
    }

    #promo-video {
        margin-left: 4em;
        margin-top: 2em;
        margin-bottom: 2em;
    }

    #titleText {
        margin-left: 4em;
    }


}

/* Styles for mobile users */
@media only screen and (max-width: 768px) {

    #hero {
        text-align: center;
        overflow: hidden;
    }

    #hero h1
    {
        font-family: 'Anton', sans-serif;
        font-size: 3em;
        text-shadow: 3px 3px 4px rgba(16, 16, 255, 1.0);

        text-align: left;
    }

    #hero h2
    {
        font-family: 'Russo One', sans-serif;
        font-size: 1em;

        text-align: left;
    }

    #hero ul {
        list-style: none;
    }

    #hero #titleText {
        margin-top: 4em;
    }

    #promo-video {
        margin-left: 4em;
        margin-top: 2em;
        margin-bottom: 2em;
    }

    #titleText {
        margin-left: 4em;
    }
}

