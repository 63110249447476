#WhatIsVAT img {
  border-radius: 1em;
}

#WhatIsVAT p {
  text-align: justify;
}

#WhatIsVAT ul {
  list-style: none;
  padding-left: 0;
}

#author-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 1em;
  line-height: 0.2em;
}
