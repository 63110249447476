
#ArticleCards {
    margin-top:     1.5em;
    margin-bottom:  1.5em;
}

#ArticleCards img {
    border-color:wheat;
    border-radius: 1em;
}

#ArticleCards .container {
    text-align: justify;
    padding-top: 1.2em;
}

#ArticleCards .container h3 {
    text-align: left;
}

#ArticleCards .ArticleSnippet {
    font-size: large;
};

#ArticleNavigation {
    margin-top: 1em;
}

.squareMargins {
    margin-left: 2em;
    margin-right: 2em;
}
