
#transactionHistoryTable {
    border-radius: 1em;
}

#tblTransactionHistory{
    width: 100%;
}

#tblTransactionHistory thead td {
    text-align: center;
}

#tblTransactionHistory tbody td {
    text-align: right;
    padding-right: 1em;
}

.export-button {
    margin-left: 1em;
    margin-right: 0.5em;
    padding: 8px 16px;

    display: inline-block;
    border: 1px solid #ccc;
}
