/* Wrapper and layout styling */
.wrapper {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.layout-row {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.layout-col {
  padding: 10px;
  box-sizing: border-box;
}

.advert-col {
  flex: 0 0 200px;
  max-width: 200px;
}

.content-col {
  flex: 1;
  min-width: 300px;
}

.spacer {
  height: 40px;
}

/* Justify text for all paragraphs and list items */
.copy, .copy p, .copy li {
  text-align: justify;
}

/* Table of Contents (TOC) styling */
#toc {
  margin-bottom: 20px;
  padding: 10px 0;
}

#toc ul {
  list-style: none;
  padding-left: 0;
}

#toc li {
  margin-bottom: 5px;
}

/* Image styling */
.image-container img {
  border-radius: 1em;
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}

/* Author box styling */
.author-box {
  font-style: italic;
  margin-bottom: 1.5em;
  text-align: left;
}

.author-box p {
  margin: 0;
}

/* Heading spacing */
#RegisterForVatScreen h2 {
  margin-top: 2.2em;
}

#RegisterForVatScreen h3 {
  margin-top: 1.2em;
}

/* Two-column layout for the postal registration section */
.two-column {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
}

.two-column .column {
  flex: 1;
  min-width: 300px;
  padding: 10px;
}

.image-links a {
  display: block;
  margin-bottom: 10px;
}
