
footer
{
    position: relative;
    bottom:   0;
    width:    100%;
    padding:  4em 0em 2em 0em;
}

.footer {
    border-top: 1px solid rgba(16, 16, 16, .1);
}

.footer .btn.btn-link {
    display: block;
    margin-bottom: 5px;
    padding: 0;
    text-align: left;
    color: var(--secondary);
    font-weight: normal;
    text-transform: capitalize;
    transition: .3s;
}

.footer .btn.btn-link::before {
    position: relative;
    content: "\f105";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    color: var(--secondary);
    margin-right: 10px;
}

.footer .btn.btn-link:hover {
    color: var(--primary);
    letter-spacing: 1px;
    box-shadow: none;
}

.footer .btn.btn-square {
    color: var(--primary);
    border: 1px solid var(--primary);
}

.footer .btn.btn-square:hover {
    color: #FFFFFF;
    background: var(--primary);
}

.footer .copyright {
    padding: 25px 0;
    border-top: 1px solid rgba(16, 16, 16, .1);
    border-bottom: 1px solid rgba(16, 16, 16, .1);
}

.footer .copyright a {
    color: var(--primary);
}

.footer .copyright a:hover {
    color: var(--dark);
}

.footer ul {
    padding-left: 0;
}

.footer li {
    font-style: italic;
    list-style-type: none;
}

#logoContainer {
    min-width: 330px;
    min-height: 300px;
}
