/* Wrapper and layout styling */
.wrapper {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}
.layout-row {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
}
.layout-col {
    padding: 10px;
    box-sizing: border-box;
}
/* For the adverts, we set a max-width */
.advert-col {
    flex: 0 0 200px;
    max-width: 200px;
}
/* Content column takes up the remaining space */
.content-col {
    flex: 1;
    min-width: 300px;
}

/* Spacer for vertical separation */
.spacer {
    height: 40px;
}

/* Justify all text within the main content area */
.copy, .copy p, .copy li {
    text-align: justify;
}

/* Remove bullet points from unordered lists */
.copy ul {
    list-style-type: none;
    padding-left: 0;
}

/* Table styling */
#VATItemsTable {
    margin-top: 40px;
    margin-bottom: 40px;
    border-radius: 1em;
    overflow: hidden;
}
#tblVATItems {
    width: 100%;
    border-collapse: collapse;
}
#tblVATItems thead td {
    text-align: center;
    font-weight: bold;
    border: 1px solid #ddd;
    padding: 10px;
}
#tblVATItems tbody td {
    text-align: left;
    padding: 10px;
    border: 1px solid #ddd;
}

/* Image styling */
#CurrentVATRateScreen img {
    border-radius: 1em;
    max-width: 100%;
    height: auto;
}

/* Heading spacing */
#CurrentVATRateScreen h2 {
    margin-top: 2.2em;
}
#CurrentVATRateScreen h3 {
    margin-top: 1.2em;
}

/* Author box styling */
.author-box {
    font-style: italic;
    margin-bottom: 1.5em;
    text-align: left;
}
.author-box p {
    margin: 0;
}
