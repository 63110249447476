#SpotlightScreen .copy p {
    margin-bottom: 1.2em;
}

#SpotlightScreen img {
    border-radius: 1em;
}

#SpotlightScreen h2 {
    margin-top: 2.2em;
}

#SpotlightScreen h3 {
    margin-top: 1.2em;
}

