
* {
    margin:  0;
    padding: 0;
}

body
{
    font-family: Russo One, sans-serif;
}

body p {
    font-family: Roboto Mono, sans-serif;
}

main
{
    min-height: 45em;
}

img {
  max-width: 85%;
  height: auto;
}


.copy {
    text-align: left;
}
