#SubmittingVatReturn .copy p {
    margin-bottom: 1.2em;
}

#SubmittingVatReturn img {
    border-radius: 1em;
}

#SubmittingVatReturn h2 {
    margin-top: 2.2em;
}
