
#SiteLogo {
    margin: 0;
    padding: 0;
}

#SiteLogo p {
    max-width: 20em;
    text-align: justify;
}

#SiteLogo .bi {
    position: absolute;
    margin-top: -0.3em;
    margin-left: -0.1em;

    font-size: 20em;
    color: white;
    text-shadow: 14px 12px 20px rgba(0, 128, 128, 1.0);
}

#SiteLogo h4 {
    margin-top: 0.5em;
    font-weight: bold;
}
