
#CalculatorScreen {

}


#CalculatorScreen #heading ul {
    list-style-type: none;
    padding: 0;
    margin-top: -1em;

    display: flex;
    gap: 10px; /* This creates space between the items */
}


/* Styles for desktop users */
@media only screen and (min-width: 768px) {

}


/* Styles for mobile users */
@media only screen and (max-width: 767px) {

}
