
/* Styles for desktop users */
@media only screen and (min-width: 768px) {

    #getting-started .bi {
        font-size: 4em;
        font-weight: 900;
    }

    .card {
        margin-top: 1em;
        min-height: 8em;
        padding-top: 2em;
        padding-bottom: 2em;
    }

    .card-title {
        text-align: center;
        margin-top:  0.5em;
        margin-left: 1em;

        text-shadow: 1px 3px 4px rgba(16, 255, 255, 1.0);
    }

    .card-body {
        text-align:justify;
    }
}


/* Styles for mobile users */
@media only screen and (max-width: 767px) {

    #getting-started .bi {
        font-size: 4em;
        font-weight: 900;
    }

    .card {
        margin-top: 1em;
        min-height: 8em;
        padding-top: 2em;
        padding-bottom: 2em;
    }

    .card-title {
        text-align: center;
        margin-top:  0.5em;
        margin-left: 1em;

        text-shadow: 1px 3px 4px rgba(16, 255, 255, 1.0);
    }

    .card-body {
        text-align: left;
    }
}
