/* Center the H1 */
#CalcVATFormulaScreen h1 {
    text-align: center;
}

/* Apply 2em spacing before each H2 */
#CalcVATFormulaScreen h2 {
    margin-top: 2em;
}

/* Justify all paragraph text */
#CalcVATFormulaScreen p {
    text-align: justify;
}

/* Center the image and maintain border-radius */
#CalcVATFormulaScreen img {
    border-radius: 1em;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

#calculate_vat_formula_snippet {
}

/* Center the author box and last updated date */
#author-info {
    width: 100%;
    text-align: center;
    margin-bottom: 1em;
}

/* Remove bullet points in unordered lists */
#CalcVATFormulaScreen ul {
    list-style-type: none;
    padding-left: 0;
}

.formulae {
    max-width: 90%;
}

.formulae strong {
    font-weight: bold;
}

.formulae h2 {
    font-size: large;
}

/* Decorate the examples with a pretty box */
.example {
    margin: 1em 2em;
    padding: 1em;
    border: 1px solid #ccc;
    background-color: #f9f9f9;
    border-radius: 0.5em;
    font-style: italic;
}
