
#IssueVatInvoiceScreen h2 {
}

#IssueVatInvoiceScreen img {
    border-radius: 1em;
}

#IssueVatInvoiceScreen section {
    margin-bottom: 4em;
}

#IssueVatInvoiceScreen .img-container {
    margin-left: 2em;
    margin-top: 1em;
    margin-bottom: 1em;
}


/* Styles for desktop users */
@media only screen and (min-width: 768px) {
}


/* Styles for mobile users */
@media only screen and (max-width: 767px) {
}
