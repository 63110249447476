#AnnualAccountingVATScreen img {
    border-radius: 1em;
}

#VATItemsTable {
    border-radius: 1em;
}

#tblVATItems {
    width: 100%;
}

#tblVATItems thead td {
    text-align: center;
    font-weight: bold;
}

#tblVATItems tbody td {
    text-align: left;
    padding: 0.8em;
}

#tblInstalments {
    border: 1px solid;
    border-color: black;
}

#tblInstalments td {
    text-align: center;
    border: 1px solid;

    min-width: 2em;
}
