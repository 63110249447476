
.youtubeIframeContent * {
  padding: 0;
  margin: 0;
  overflow: hidden;
}

.youtubeIframeContent body, html {
  height: 100%;
}

.youtubeIframeContent img, svg {
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  margin: auto;
}

