
.navbar-brand {
    margin-left: 1em;
    margin-top: 1em;
}

.navbar ul {
    list-style-type: none;
    display: flex; /* display list items horizontally */
    justify-content: space-around;
    gap: 3em; /* add space between list items */
}
