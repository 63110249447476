/* General Styles for AboutUsScreen */
#AboutUsScreen img {
  border-radius: 1em;
}

/* Justify all paragraph text */
#AboutUsScreen p {
  text-align: justify;
}

/* Center the main heading and images */
#AboutUsScreen h1 {
  text-align: center;
}

.centered-image {
  display: block;
  margin: 1.5em auto;
}

/* Spacing for headings */
#AboutUsScreen h2 {
  margin-top: 2.2em;
}

#AboutUsScreen h3 {
  margin-top: 1.2em;
}

/* Spacing for sections */
#AboutUsScreen section {
  margin-bottom: 2em;
}

/* Circular images for team members */
#AboutUsScreen img.circular {
  border-radius: 50%;
  border: 2px solid #333;
}

/* Styling for quotations (if needed) */
#AboutUsScreen .quotation {
  margin-left: 3em;
  font-size: 1.4em;
  font-style: italic;
  font-weight: 500;
}

/* Container styling for individual team sections */
#the-shazzle,
#the-lapin {
  text-align: left;
}

/* Justify paragraph text in team details (redundant now with global rule, but kept if specific adjustments are needed) */
#the-lapin p,
#the-shazzle p {
  text-align: justify;
}

/* Reset top margins for headings in team sections */
#the-lapin h2,
#the-shazzle h2 {
  margin-top: 0;
}

#the-lapin h3,
#the-shazzle h3 {
  margin-top: 0.3em;
}

/* Optional: Adjust margins for team member images */
#shazzle-pic {
  margin-top: 0;
}

/* Responsive Styles for Mobile Users */
@media only screen and (max-width: 767px) {
  /* You can add mobile-specific adjustments here if needed */
}
