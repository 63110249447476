
#Basic404Screen
{
  color:              #fff;
  background-color:   #d00;
}

#Basic404Screen img {
    border-radius: 1em;
}


#Basic404Screen h2 {
    margin-top: 2.2em;
}

.notfound-404
{
  text-align:   center;
  margin:       1em;
}

.notfound-404 h1
{
  font-size:    440px;
  font-weight:  900;
  margin:       0.2em 0.2em 0 0;
  text-shadow:  2px 2px #000;
}

.notfound-404 h2
{
  color:         #000;
  font-size:      44px;
  font-weight:    700;

  text-transform: uppercase;

  margin-bottom:  2em;
}

.notfound-404 h3
{
  font-size:      28px;
  font-weight:    700;

  margin-top:      2em;
  margin-bottom:  -4em;

  text-shadow:    1px 1px #000;
  text-transform: uppercase;
}

.quotation
{
    font-style: italic;
    text-shadow: 1px 1px #000;
}

.quotation-author
{
    text-shadow: 1px 1px #000;
    font-weight: bold;
}




/* Styles for desktop users */
@media only screen and (min-width: 768px) {
}


/* Styles for mobile users */
@media only screen and (max-width: 767px) {
}
