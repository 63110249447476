

#ArticlesScreen .copy p {
    margin-bottom: 1.2em;
}

#ArticlesScreen img {
    border-radius: 1em;
}

#ArticlesScreen h2 {
    margin-top: 2.2em;
}

#ArticlesScreen h3 {
    margin-top: 1.2em;
}




/* Styles for desktop users */
@media only screen and (min-width: 768px) {

}


/* Styles for mobile users */
@media only screen and (max-width: 767px) {

}
