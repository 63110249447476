#calculator {
  width: 20rem;
  padding:            1em;
  background-color:   #333741;
  border-radius:      1em;
  margin-bottom: 2em;
}

#calculator input {
  width: 100%;

  text-align:     right;
  font-size:      xx-large;
  font-weight:    bold;

  background-color: #ffffe5;
  margin:   0.2rem 0;
  padding:  0 0.2rem;
}

#calculator input #userInput::placeholder{
  color: #cccccc;
  opacity: 0.5;
}

#calculator input #vatOnlyInput::placeholder{
  color: #cccccc;
  opacity: 0.5;
}

#calculator input #resultInput::placeholder{
  color: #cccccc;
  opacity: 0.5;
}

#calculator button {
  border-radius: 0.2rem;
  margin-top: 0.6rem;
  width: 5rem;
  font-size: 1.4rem;
  font-weight: bold;
  transition: background-color 0.2s ease-in-out;

box-shadow: 0px 5px 10px #8080a0;
}

#calculator button:hover {
  box-shadow: 0px 2px 5px #333333;
}

#btnClear{
  background-color: orangered;
}

#btnClear:hover{
  filter: brightness(85%);
}

#btnCalc{
  background-color: blue;
  padding-left: 0.5em;
  font-size: 1.2em;
}

#btnCalc:hover{
  filter: brightness(85%);
}
