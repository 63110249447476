
.country ul {
    list-style-type: none;
}

.counties {
    column-count: 1;
    column-gap: 0.25em;
}

.counties li {
    break-inside: avoid-column;
}


/* For smallest screens, say phones/tablets in landscape mode */
@media (min-width: 420px) {
    .counties { column-count: 2; }
}

/* For larger screens, say tablets in landscape mode */
@media (min-width: 768px) {
  .counties {
    column-count: 3;
  }
}

/* For small desktop screens */
@media (min-width: 992px) {
  .counties {
    column-count: 4;
  }
}

/* For large desktop screens */
@media (min-width: 1200px) {
  .counties {
    column-count: 5;
  }
}
