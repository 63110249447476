
#QuickLinksMenu {
}

#QuickLinksMenu h4 {
    font-weight: bold;
}

#QuickLinksMenu p {
    font-weight: bold;
}

#QuickLinksMenu .nav .nav-link {
    font-weight: bold;
}

#QuickLinksMenu .nav .nav-link:hover {
    -webkit-transition: .4s;
    transition: .4s;

    text-shadow: 0px 0px 10px #ffff44;
}
