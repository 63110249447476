
#ContactUsScreen ul {
    list-style: none;
}


#ContactUsScreen img {
    border-radius: 1em;
}

/* Styles for desktop users */
@media only screen and (min-width: 768px) {
}


/* Styles for mobile users */
@media only screen and (max-width: 767px) {
}
