.footer {
  position: relative;
  bottom: 0;
  width: 100%;
  padding: 4em 0 2em 0;
  background: #f8f9fa;
  border-top: 1px solid rgba(16, 16, 16, 0.1);
}

.footer-wrapper {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1em;
}


.footer-container {
  padding: 2em 0;
}

.footer-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 2em;
}


.footer-section {
  flex: 1;
  min-width: 250px;
}


.left-section {
  flex-basis: 100%;
}


.footer-title {
    color: #007bff;
    margin-bottom: -0.1em;
}


.footer-text {
  font-size: 1em;
  line-height: 1.5;
}

#logoContainer {
  min-width: 330px;
  min-height: 300px;
}

.footer-bottom {
  border-top: 1px solid rgba(16, 16, 16, 0.1);
  padding: 1em 0;
}

.footer-bottom-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.footer-bottom-left {
  text-align: left;
  flex: 1;
  min-width: 200px;
}

.footer-bottom-right {
  text-align: right;
  flex: 1;
  min-width: 200px;
}

.footer-bottom-right a {
  color: #007bff;
  text-decoration: none;
}

.footer-bottom-right a:hover {
  text-decoration: underline;
}
